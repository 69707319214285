








import { SubscriptionActionInfo } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'TimelineControl'
})
export default class TimelineDateStub extends Mixins(ComponentHelper) {
  @Prop({ required: true }) item!: SubscriptionActionInfo

  formatDate (date: Date) {
    const day = date.getDate()
    const suffix = this.ordinalSuffixOf(day)
    const options = { day: 'numeric', month: 'long', year: 'numeric' } as const
    return date.toLocaleString(this.localeData.iso, options).replace(/(\d+)/, `${suffix}`)
  }
}

