













import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Api, SubscriptionActionInfo } from '@/edshed-common/api'
import _ from 'lodash'
import moment from 'moment'
import TimelineItem from './TimelineItem.vue'
import TimelineDateStub from './TimelineDateStub.vue'
import TimelineDateNow from './TimelineDateNow.vue'

@Component({
  name: 'Timeline',
  components: { TimelineItem, TimelineDateStub, TimelineDateNow }
})
export default class Timeline extends Mixins(ComponentHelper) {
  @Prop({ default: () => [] }) actions!: SubscriptionActionInfo[]

  @Prop({ default: () => () => null }) headerClicked!: (action: SubscriptionActionInfo) => void

  confirmCancel (actions: SubscriptionActionInfo[]) {
    this.$buefy.dialog.confirm({
      message: `You are about to cancel ${actions.length} job${actions.length > 1 ? 's' : ''}. You will need to manually recreate these actions if you want to undo.`,
      type: 'is-warning',
      confirmText: `Cancel job${actions.length > 1 ? 's' : ''}`,
      cancelText: 'Changed my mind',
      onConfirm: () => this.cancelJobs(actions)
    })
  }

  async cancelJobs (actions: SubscriptionActionInfo[]) {
    try {
      await Promise.all(actions.map(async (a) => {
        await Api.cancelSubscriptionPlanAction(a.db_data?.job_id ?? a.queue_data!.job_id)
      }))

      this.$buefy.toast.open({
        message: `Action${actions.length > 1 ? 's' : ''} cancelled`,
        position: 'is-bottom',
        type: 'is-success'
      })
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: `Could not cancel action${actions.length > 1 ? 's' : ''}`,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }

    this.$emit('cancelled-jobs')
  }

  get items () {
    const dates = this.actions.map(a => moment(a.date).startOf('day').toDate())

    const unqDates = Array.from(new Set(dates.map(d => d.toISOString())))

    const groupedActions: SubscriptionActionInfo[][] = Object.values(_.groupBy(this.actions, a => a.batch_id))

    const mappedActions = groupedActions.map(a => ({
      type: 'Action' as const,
      data: a
    }))

    const temp = [...mappedActions, ...unqDates.map(d => ({
      type: 'Date' as const,
      data: {
        date: new Date(d)
      }
    })),
    {
      type: 'NOW' as const,
      data: {
        date: new Date()
      }
    }].sort((a, b) => {
      const aData = Array.isArray(a.data) ? a.data[0] : a.data
      const bData = Array.isArray(b.data) ? b.data[0] : b.data

      return moment(aData.date).isBefore(a.type === 'Date' ? moment(bData.date).startOf('day') : moment(bData.date)) ? 1 : -1
    })

    return temp
  }
}
