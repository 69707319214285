







































import { Api, SubscriptionActionInfo } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { UnreachableCaseError } from 'ts-essentials'

@Component({
  name: 'TimelineItem'
})
export default class TimelineItem extends Mixins(ComponentHelper) {
  @Prop({ required: true }) item!: SubscriptionActionInfo[]

  getTime () {
    return moment(this.item[this.currentIndex].date).format('hh:mm')
  }

  currentPage = 1

  get currentIndex () {
    return this.currentPage - 1
  }

  get isFuture () {
    return moment().isBefore(moment(this.item[this.currentIndex].date))
  }

  get headerText () {
    return this.item[this.currentIndex].plan?.sku ?? 'Unknown Plan'
  }

  get bodyText () {
    switch (this.type) {
      case 'activate':
        return `This plan will activate on ${new Date(this.item[this.currentIndex].date).toLocaleDateString()} ${new Date(this.item[this.currentIndex].date).toLocaleTimeString()}.`
      case 'migrate_renewal':
        if (this.item[this.currentIndex].to_plan === undefined) {
          return `Support for this add-on will be dropped on ${new Date(this.item[this.currentIndex].date).toLocaleDateString()} ${new Date(this.item[this.currentIndex].date).toLocaleTimeString()}. ${this.item[this.currentIndex].behaviour === 'keep' ? 'Existing subscriptions with this plan will not be effected' : 'This add-on will be removed from existing subscriptions when they renew'}`
        } else {
          return `This plan will schedule migrations to ${this.item[this.currentIndex].to_plan?.sku} for subscriptions renewing on or after ${new Date(this.item[this.currentIndex].date).toLocaleDateString()} ${new Date(this.item[this.currentIndex].date).toLocaleTimeString()}.`
        }
      case 'deprecate':
        return `This plan will be deactivated on ${new Date(this.item[this.currentIndex].date).toLocaleDateString()} ${new Date(this.item[this.currentIndex].date).toLocaleTimeString()}.`
      case undefined:
        throw new Error(undefined)
      default:
        throw new UnreachableCaseError(this.type)
    }
  }

  get errorText () {
    return this.item[this.currentIndex].error
  }

  get status () {
    return this.item[this.currentIndex].status
  }

  get iconForStatus () {
    switch (this.status) {
      case 'completed':
        return 'check-bold'
      case 'failed':
        return 'alert-circle'
      case 'delayed':
        return 'calendar-clock'
      case 'active':
        return 'run'
      case 'waiting':
        return 'timer-sand'
      case 'paused':
        return 'pause'
      case 'stuck':
        return 'timer-sand-paused'
      default:
        throw new UnreachableCaseError(this.status)
    }
  }

  get type () {
    return this.item[this.currentIndex].type
  }

  get actionFromType () {
    switch (this.type) {
      case 'activate':
        return 'activate'
      case 'migrate_renewal':
        return 'start scheduling migrations at renewal'
      case 'deprecate':
        return 'deprecate'
      case undefined:
        return 'do an unknown task'
      default:
        throw new UnreachableCaseError(this.type)
    }
  }

  get didFail () {
    return this.status === 'failed'
  }

  get jobId () {
    return this.item[this.currentIndex].job_id
  }

  headerClicked () {
    this.$emit('header-clicked', this.item[this.currentIndex])
  }

  cancelEvent () {
    this.$emit('cancel', this.item[this.currentIndex])
  }

  async retryEvent () {
    try {
      if (!this.jobId) {
        throw new Error('Unknown job id')
      }

      const success = await Api.retryPlanAction(this.jobId)

      this.$buefy.toast.open({
        message: success ? 'Job succeeded' : 'Job failed',
        type: success ? 'is-success' : 'is-danger',
        position: 'is-bottom'
      })
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Job failed',
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }
}
